<template>
    <div class="project">
        <p>选择扣费项目：</p>
         <van-field readonly clickable  name="picker"  :value="name"  placeholder="请选择扣费项目"  @click="showPicker = true" />
        <van-popup v-model="showPicker" position="bottom" :close-on-click-overlay="false">
        <van-picker
            show-toolbar
            :columns="dataList"
            value-key="test"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
        </van-popup>
         <p>价格</p>
         <van-field v-model="Isobj.price" type="number"  placeholder="请输入单价"/>
        <p>扣费项目数量：</p>
         <van-field v-model.number="number"  placeholder="请输入扣费项目数量" :formatter="formatter" :error-message="errorText" />
         <div class="van-btn">
             <van-button type="info" size="large" @click="onsubmit">确定</van-button>
         </div>
    </div>
</template>

<script>
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import { is_post } from "@/Http/api";
    export default {
        data() {
            return {
                number:'',
                dataList:[],
                name:"",
                showPicker:false,
                errorText:"",
                price:"",
                isArr:[],
                Isobj:{},
            }
        },
      
        methods: {
          
            formatter(val){
                if(!val){
                    this.errorText ='增费项目数量不能为空'
                }else{
                    this.errorText =''
                }
                return val
            },
            onsubmit() {
                console.log()
                if(this.Isobj.price <= 0 ){
                    this.$toast.fail('扣费单价不能小于零')
                }else{

                    if(Number(this.number) <= 0 ){
                        this.$toast.fail('数量不能小于等于零')
                    }else{
                        this.$set(this.Isobj,'num',this.number)
                        this.$emit('FeeOroject',this.Isobj)
                    }
                }
                
            },
            onConfirm(a){
                if(a){
                    this.isArr.forEach(i=>{
                        if(i.id == a.id) this.Isobj = i
                    })
                    this.name = a.test
                }
           
                this.showPicker = false
            },
             getData(){
                this.name = ''
                this.number = ''
                this.price = ''
                this.Isobj = {}
                   this.$http.post('index.php/quality_price_deduction/_list').then(res=>{
                       if(res.data.code == 0){
                           this.isArr =res.data.data
                           let arr = []
                           res.data.data.forEach(i => {
                               let obj = {
                                   test:i.name ,
                                    price: i.price,
                                    id:i.id
                               }
                               arr.push(obj)
                           });
                           this.dataList = arr
                       }else if(res.data.code == -1) {
                           this.islogout()
                       }else{
                           this.$toast.fail(res.data.msg)
                       }
                   })
                }
        },
    }
</script>

<style lang="scss" scoped>
.project{
    padding: 20px;
   .van-btn{
       margin-top: 50px;
   }
}
</style>