<template>
    <div>
        <div class="isheader">
             <!-- <span class="is-span-header go" @click="$router.push('/Quality')"></span> -->
             <span class="is-span-header"></span>
             <span class="is-span-header" @click="$emit('closepopup')">
                 <van-icon name="close" />
             </span>
        </div>
        <div class="content">
            <div class="list-div">
                <p>选择运输批次：</p>
                <van-field readonly  :value=" infoDta.batch_number + '--' + infoDta.carNumberPlate"   />
                
                <hr>
            </div>
            <div class="list-div">
                <p>过磅重量：</p>
                <van-row gutter="20">
                    <van-col span="14">
                        <van-field v-model="from.batch_average.batch_average_weight"   @focus="iskong(1)"
                        :formatter="formatter" :error-message="errorText"
                        placeholder="请输入过磅重量" :rules="[{ required: true, message: '请输入过磅重量' }]" />
                    </van-col>
                    <van-col span="10"> 
                        <select class="list-select1" v-model="from.batch_average.batch_average_type">
                            <option :value ="1">Kg</option>
                            <option :value ="2">吨</option>
                        </select>
                    </van-col>
                </van-row>
                <hr>
               
            </div>
            <div class="list-div">
                <p>扣杂重量：</p>
                <van-row gutter="20">
                    <van-col span="14">
                        <van-field @focus="iskong(2)" v-model="from.suttle_batch_average.suttle_batch_average_weight" placeholder="请输入扣杂重量"  />
                    </van-col>
                    <van-col span="10"> 
                        <select class="list-select1" v-model="from.suttle_batch_average.suttle_batch_average_type">
                            <option :value ="1">Kg</option>
                            <option :value ="2">吨</option>
                        </select>
                    </van-col>
                </van-row>
                <hr>
            </div>
            <p class="sun">
                <span>净重：</span><span>{{N}}Kg</span>
            </p>
            <br>
            <div class="list-div" v-if="infoDta.purchaseType ==3">
                 <p>罚款：</p>
                <van-field label="罚款金额：" @focus="iskong(3)" v-model="from.fine_price" placeholder="请输入罚款金额"  />
                <van-field label="罚款备注："  v-model="from.fine_remark" placeholder="请输入罚款备注"  />
            </div>
        </div>
        <div class="table" v-if="tableData.length!=0">
            <table >
                <tr>
                    <td>项目</td>
                    <td>单价</td>
                    <td>数量</td>
                    <td>增费</td>
                    <td>操作</td>
                </tr>
                <tr v-for="(i,index) in tableData" :key="i.id">
                    <td>{{i.name}}</td>
                    <td>{{i.price}}</td>
                    <td>{{i.num}}</td>
                    <td>{{i.num * i.price}}</td>
                    <td @click="deletes(index,1)">删除</td>
                </tr>
            </table>
        </div>
        
        <p class="sun" v-if="tableData.length!=0">
            <span>质检增费合计：</span><span>{{Q}}</span>
        </p>
          <div class="table"  v-if="DetetData.length!=0">
            <table >
                <tr>
                    <td>项目</td>
                    <td>单价</td>
                    <td>数量</td>
                    <td>扣费</td>
                    <td>操作</td>
                </tr>
                <tr v-for="(i,index) in DetetData" :key="i.id">
                    <td>{{i.name}}</td>
                    <td>{{i.price}}</td>
                    <td>{{i.num}}</td>
                    <td>{{i.num * i.price}}</td>
                    <td @click="deletes(index,2)">删除</td>
                </tr>
            </table>
        </div>
        <p class="sun"  v-if="DetetData.length!=0">
            <span>质检扣费合计：</span><span>{{QQ}}</span>
        </p>
        <div class="btn-nn">
            <van-button type="primary" size="large" @click="addProject">添加/修改质检增费项目</van-button>
            <van-button type="primary" size="large" @click="addDetention">添加/修改质检扣费项目</van-button>
            <van-button v-if="infoDta.purchaseType ==3" type="info" size="large" @click="onShow = true">提交</van-button>
            <van-button v-if="infoDta.purchaseType !=3" type="info" size="large" @click="onSubmit">提交</van-button>
        </div>
        <van-popup v-model="show" closeable class="popupProject" :close-on-click-overlay="false">
            <IncreaseTheProject ref="Increase" @addOroject="addOroject" />
        </van-popup>
        <van-popup v-model="FeeShow" closeable class="popupProject" :close-on-click-overlay="false">
            <feeDeduction ref="DetentRef" @FeeOroject="FeeOroject" />
        </van-popup>
        <van-popup v-model="onShow" closeable class="popupProject" :close-on-click-overlay="false">
            <div class="popup_tijiao">
                <h3>提交</h3>
                <van-field
                    v-model="from.remark"
                    rows="3"
                    autosize
                    label="进厂质检备注"
                    type="textarea"
                    placeholder="请输入质检备注"
                    />
                <hr>
                <van-button type="info" size="large" @click="onSubmit">提交</van-button>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { is_post } from "../../Http/api"
import IncreaseTheProject from './components/IncreaseTheProject.vue'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import feeDeduction from './components/feeDeduction.vue'
    export default {
        props:['infoDta'],
        components: {
            IncreaseTheProject,feeDeduction
        },
        data() {
            return {
                batchList:[],
                DetetData:[],
                showPicker:false,//下拉选择
                show:false,//添加/修改质检增费项目
                onShow:false,//添加/修改质检增费项目
                FeeShow:false,//添加/修改质检扣费项目
                from:{
                    batch_id:'',//批次Id
                    batch_average:{
                        batch_average_type:1,//过磅类型
                        batch_average_weight:""//过磅重量
                    }
                    ,
                    suttle_batch_average:{
                        suttle_batch_average_weight:0,//扣杂重量
                        suttle_batch_average_type:1,//扣杂类型
                    },
                    quality_inspection:"",
                    fine_price :0,//罚款
                    fine_remark :'',//罚款备注
                    remark :'',//备注
                },
                batch_id_name:'',//批次名字
                tableData:[],
                errorText:'',
            }
        },
        computed: {
            N() {
                let he = 0
                let average =  this.from.batch_average.batch_average_weight
                
                let batch_average =  this.from.suttle_batch_average.suttle_batch_average_weight

                if(this.from.suttle_batch_average.suttle_batch_average_type == 2){
                    
                    batch_average *= 1000
                }
                if(this.from.batch_average.batch_average_type == 2){
                    
                    average *= 1000
                }
                if(Number(batch_average) > Number(average)){
                    this.$toast.fail('扣杂重量不能大于过磅重量')
                }else{
                    he = average - batch_average
                }
                return he
                
            },
            Q() {
                let num = 0
                if(this.tableData.length!=0){
                   this.tableData.forEach(i=>{
                       num += Number(i.num * i.price)
                   })
                }
                return num
            },
            QQ() {
                let num = 0
                if(this.DetetData.length!=0){
                   this.DetetData.forEach(i=>{
                       num += Number(i.num * i.price)
                   })
                }
                return num
            },
        },
        mounted () {
            this.lodData();
        },
        methods: {
            get(val){
                this.$http.post('index.php/batch/getWeight',{id:val.id}).then(res=>{
                    if(res.data.code == 0){
                       this.from.batch_average.batch_average_weight = res.data.data.guoBang
                    }else if(res.data.code == -1){
                        this.islogout()
                    }else {
                        this.$toast.fail(res.data.msg)
                    }
                })
            },
            iskong(i){
                if(i == 1){
                    this.from.batch_average.batch_average_weight = this.from.batch_average.batch_average_weight == 0 ? null: from.batch_average.batch_average_weight
                }else if(i == 2){
                    this.from.suttle_batch_average.suttle_batch_average_weight = this.from.suttle_batch_average.suttle_batch_average_weight == 0 ? null: from.suttle_batch_average.suttle_batch_average_weight
                }else if(i == 3){
                    this.from.fine_price = this.from.fine_price == 0 ? null:  this.from.fine_price
                }
               },
            formatter(val){
                if(!val){
                    this.errorText ='过磅重量不能为空'
                }else{
                    this.errorText =''
                }
                return val
            },
            kk(){
                this.from.batch_average.batch_average_type = 1
                this.from.batch_average.batch_average_weight = 0
                this.from.suttle_batch_average.suttle_batch_average_weight = 0
                this.from.suttle_batch_average.suttle_batch_average_type = 1
                this.tableData = []
                this.DetetData = []
                this.from.fine_price=0
                this.from.fine_remark=''
                this.from.remark=''
            },
        
            deletes(i,num){
                if(num == 1 ){
                    this.tableData.splice(i,1)
                }else {
                    this.DetetData.splice(i,1)
                }
               
            },
            addOroject(e){
                console.log(e)
                this.show = false
                let o=false
                this.tableData.forEach(i=>{
                    if(i.id === e.id){
                        i.num = e.num
                        o = true
                    }
                })
                if(!o){
                    this.tableData.push(e)
                }
            },
            FeeOroject(e){
                 this.FeeShow = false
                 let o=false
                this.DetetData.forEach(i=>{
                    if(i.id === e.id){
                        i.num = e.num
                        o = true
                    }
                })
                if(!o){
                    this.DetetData.push(e)
                }
            },
            addDetention(){
                this.FeeShow = true
                 this.$nextTick(()=>{
                    this.$refs.DetentRef.getData()
                })
            },
            addProject(){
                this.show = true
                this.$nextTick(()=>{
                    this.$refs.Increase.getData()
                })
            },
            onConfirm(v){
                this.from.batch_id = v.id
                this.batch_id_name = v.test
                this.showPicker = false
            },
            async lodData() {
                try{
                     const {data} = await is_post('index.php/batch/listBatch').then(res=>res)
                     console.log(data)
                    let arr = []
                    data.data.data.forEach(i => {
                        let obj = {
                            test:i.batch_number + '--' +i.carNumberPlate,
                            id:i.id,
                            status:i.status
                        }
                        arr.push(obj)
                    });
                    this.batchList = arr
                }catch{}
               
            },
            async onSubmit(){
                try{
                     let arr = {}
                     let arr1 = {}
                    this.tableData.forEach(i=>{
                        let obj = {
                            [i.id]:{
                                price:i.price,
                                num:i.num
                            }
                        }
                        arr = {...arr,...obj}
                    })
                    this.DetetData.forEach(i=>{
                        let obj = {
                            [i.id]:{
                                price:i.price,
                                num:i.num
                            }
                        }
                        arr1 = {...arr1,...obj}
                    })
                    this.from.quality_inspection = arr
                    this.from.deduction_price = arr1
                    this.from.batch_id = this.infoDta.id
                    this.from.batch_number = this.infoDta.batch_number
                    if(this.from.batch_average.batch_average_weight !=0 ){
                        let data = encipherMent( JSON.stringify(this.from))
                        let param = new URLSearchParams();
                        param.append("value",data);
                        let url = this.infoDta.purchaseType ==3 ?'index.php/scrap_iron/qualityInspection':'index.php/batch/qualityInspection'
                        const res = await is_post(url,param).then(res=>res)
                        this.$toast.success('添加成功')
                        this.$router.push('/Quality')
                    }else{
                        this.$toast.fail('过磅重量不可为空')
                    }
                }
                catch (err){
                    console.log(err)
                }
               
            }
        },
    }
</script>

<style lang="scss" scoped>
.content{
    margin-top: 50px;
    padding: 10px;
    overflow: hidden;
    
}
.sun{
        float: right;
        padding-right: 10px;
    }
.list-div{
    .list-select1{
        width: 40%;
    }
}
.table{
    padding:10px;
    table,table tr th, table tr td { border:1px solid #000; }
    table { 
        width: 100%;
        text-align: center; 
        border-collapse: collapse; 
        
    }
}
.btn-nn{
    margin-top: 50px;
    padding: 10px;
    .van-button{
        border-radius: 8px;
        margin: 5px 0;
    }
}
.popupProject{
    width: 80%;
}
.popup_tijiao{
    padding: 20px;
    h3{
        text-align: center;
    }
}
</style>